/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Route, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import VerticalLayout from './components/VerticalLayout';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';

import WebchatWidget from './components/WebchatWidget';

const store = require('store');

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout;
    const { layout } = props;
    const { layoutType } = layout;
    switch (layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const [funcionalidades, setFuncionalidades] = useState(store.get('funcionalidades'));

  store.watch('funcionalidades', () => {
    setFuncionalidades(store.get('funcionalidades'));
  });

  const Layout = getLayout();
  return (
    <>
      <Router basename="/nuevo">
        <Route>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              funcionalidades={funcionalidades}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected
              funcionalidades={funcionalidades}
              exact
            />
          ))}
        </Route>
      </Router>
      <WebchatWidget />
    </>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
