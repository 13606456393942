import React from 'react';
import { Redirect } from 'react-router-dom';
import loadable from '@loadable/component';

// Inner Authentication
const Login = loadable(() => import('../pages/AuthenticationInner/Login'));
const RecuperarPassword = loadable(() => import('../pages/AuthenticationInner/RecuperarPassword'));
const CompruebaEmail = loadable(() => import('../pages/AuthenticationInner/CompruebaEmail'));
const CambiarPassword = loadable(() => import('../pages/AuthenticationInner/CambiarPassword'));
const CambiarPasswordNueva = loadable(() => import('../pages/AuthenticationInner/CambiarPasswordNueva'));

// Perfil Usuario
const PerfilUsuario = loadable(() => import('../pages/PerfilUsuario/index'));

// alertas
const AlertasFeedback = loadable(() => import('../pages/Alertas/Feedback/index'));
const AlertasCheckin = loadable(() => import('../pages/Alertas/Checkin/index'));
const AlertasSistemas = loadable(() => import('../pages/Alertas/Sistemas/index'));
const AlertasMessagingCenter = loadable(() => import('../pages/Alertas/MessagingCenter/index'));
const AlertaFeedback = loadable(() => import('../pages/Alertas/Feedback/alertas'));
const AlertaCheckin = loadable(() => import('../pages/Alertas/Checkin/alertas'));
const AlertaSistemas = loadable(() => import('../pages/Alertas/Sistemas/alertas'));
const AlertaMessagingCenter = loadable(() => import('../pages/Alertas/MessagingCenter/alertas'));
const AlertasPagos = loadable(() => import('../pages/Alertas/Pagos/index'));
const AlertaPagos = loadable(() => import('../pages/Alertas/Pagos/alertas'));

// reputacion
const InvitacionesReview = loadable(() => import('../pages/Reputacion/index'));
const Review360 = loadable(() => import('../pages/Reputacion/review360'));

// Locales
const ListadoClientes = loadable(() => import('../pages/Locales/ListadoClientes'));
const ListadoReservas = loadable(() => import('../pages/Locales/ListadoReservas'));
const ListadoCheckin = loadable(() => import('../pages/Listado/ListadoCheckin'));
const DetalleCheckin = loadable(() => import('../pages/Locales/DetalleCheckin'));

// Messaging Center
const Inbox = loadable(() => import('../pages/MessagingCenter/inbox'));
const ReglasAsignacion = loadable(() => import('../pages/MessagingCenter/reglasAsignacion'));
const Tareas = loadable(() => import('../pages/MessagingCenter/tareas'));
const Pipeline = loadable(() => import('../pages/MessagingCenter/pipeline'));
const Estadisticas = loadable(() => import('../pages/MessagingCenter/estadisticas'));
const RespuestasRapidas = loadable(() => import('../pages/MessagingCenter/respuestasRapidas'));
const ListadoClientesMessaging = loadable(() => import('../pages/MessagingCenter/ListadoClientesMessaging'));
const DiagramaChatbot = loadable(() => import('../pages/MessagingCenter/diagramaChatbot'));
const DiagramaChatbotWhatsapp = loadable(() => import('../pages/MessagingCenter/diagramaChatbotWhatsapp'));
const ListadoDiagramasChatbot = loadable(() => import('../pages/MessagingCenter/ListadoDiagramasChatbot'));
const ConfiguracionMC = loadable(() => import('../pages/MessagingCenter/ConfiguracionMC'));
const ConfiguracionMCWhatsapp = loadable(() => import('../pages/MessagingCenter/ConfiguracionMCWhatsapp'));
const ConfiguracionMCEstado = loadable(() => import('../pages/MessagingCenter/ConfiguracionMCEstado'));
const ConfiguracionMCEmail = loadable(() => import('../pages/MessagingCenter/ConfiguracionMCEmail'));
const AltaWhatsapp = loadable(() => import('../pages/MessagingCenter/AltaWhatsapp'));

// Whatsapp
const Automatizacion = loadable(() => import('../pages/Whatsapp/Automatizacion'));
const Plantillas = loadable(() => import('../pages/Whatsapp/Plantillas'));
const RegistroEnvios = loadable(() => import('../pages/Whatsapp/RegistroEnvios'));
const Costes = loadable(() => import('../pages/Whatsapp/Costes'));
const NuevaPlantilla = loadable(() => import('../pages/Whatsapp/nuevaPlantilla'));
const EditaPlantilla = loadable(() => import('../pages/Whatsapp/editaPlantillaWhatsapp'));
const Alta = loadable(() => import('../pages/Whatsapp/Alta'));

// Administracion
const Miembros = loadable(() => import('../pages/Administracion/Miembros'));
const Miembros2 = loadable(() => import('../pages/Administracion/Miembros2'));
const LogAcceso = loadable(() => import('../pages/Administracion/LogAcceso'));
const Miembro = loadable(() => import('../pages/Administracion/CrearMiembros'));
const ListadoDuplicadosActivos = loadable(() => import('../pages/Administracion/ListadoDuplicadosActivos'));

// Estadísticas
const EstadisticasGeneral = loadable(() => import('../pages/Estadisticas/Estadisticas'));

// Locales
const ListadoDuplicados = loadable(() => import('../pages/Locales/ListadoDuplicados'));

// Panel visitantes
const PanelVisitantes = loadable(() => import('../pages/Listado/PanelVisitantes'));

// Segmentos
const Segmentos = loadable(() => import('../pages/Segmentos/segmentos'));

// Guest Journey
const Automatismos = loadable(() => import('../pages/GuestJourney/Automatismos'));
const RegistroEnviosAutomatismos = loadable(() => import('../pages/GuestJourney/RegistroEnvios'));
const NuevoAutomatismoMail = loadable(() => import('../pages/GuestJourney/NuevoAutomatismoMail'));
const InformeAutomatismo = loadable(() => import('../pages/GuestJourney/InformeAutomatismo'));

// Campañas
const CampanasCorreo = loadable(() => import('../pages/Camps/CampsCorreo'));
const Informe = loadable(() => import('../pages/Camps/Informe'));
const NuevaCampanaMail = loadable(() => import('../pages/Camps/NuevaCampMail'));
const CampanasWhatsapp = loadable(() => import('../pages/Camps/CampsWhatsapp'));
const NuevaCampanaWhastapp = loadable(() => import('../pages/Camps/NuevaCampWhastapp'));

// Contenido
const PlantillasCorreo = loadable(() => import('../pages/Contenido/PlantillasCorreo'));
const NuevaPlantillaCorreo = loadable(() => import('../pages/Contenido/NuevaPlantillaCorreo'));
const VerPlantillaCorreoExterno = loadable(() => import('../pages/Contenido/VerPlantillaCorreoExterno'));

// Ficha de cliente
const FichaCliente = loadable(() => import('../pages/FichaCliente/FichaCliente'));
// Pagos
const PagosClientes = loadable(() => import('../pages/Pagos/PagosClientes'));
// Home
const Home = loadable(() => import('../pages/Home/Home'));
// Configuracion
const General = loadable(() => import('../pages/Configuracion/General'));
const Social = loadable(() => import('../pages/Configuracion/Social'));
const Idiomas = loadable(() => import('../pages/Configuracion/Idiomas'));
const Hotspot = loadable(() => import('../pages/Configuracion/Hotspot'));

const userRoutes = [
  // Perfil Usuario
  { path: '/perfil-usuario', component: PerfilUsuario },

  // alertas
  { path: '/alertas/feedback', component: AlertasFeedback },
  { path: '/alertas/feedback/:idAlerta', component: AlertaFeedback },
  { path: '/alertas/checkin', component: AlertasCheckin },
  { path: '/alertas/checkin/:idAlerta', component: AlertaCheckin },
  { path: '/alertas/sistemas', component: AlertasSistemas },
  { path: '/alertas/sistemas/:idAlerta', component: AlertaSistemas },
  { path: '/alertas/messagingcenter', component: AlertasMessagingCenter },
  { path: '/alertas/messagingcenter/:idAlerta', component: AlertaMessagingCenter },
  { path: '/alertas/pagos', component: AlertasPagos },
  { path: '/alertas/pagos/:idAlerta', component: AlertaPagos },

  // review 360
  { path: '/reputacion/invitaciones', component: InvitacionesReview },
  { path: '/reputacion/review360', component: Review360 },

  // Messaging Center
  { path: '/messagingcenter/inbox', component: Inbox },
  { path: '/messagingcenter/reglas', component: ReglasAsignacion },
  { path: '/messagingcenter/tareas', component: Tareas },
  { path: '/messagingcenter/pipeline', component: Pipeline },
  { path: '/messagingcenter/estadisticas', component: Estadisticas },
  { path: '/messagingcenter/respuestasRapidas', component: RespuestasRapidas },
  { path: '/messagingcenter/listadoClientes', component: ListadoClientesMessaging },
  { path: '/messagingcenter/configuracionWebchat', component: ConfiguracionMC },
  { path: '/messagingcenter/configuracionWhatsapp', component: ConfiguracionMCWhatsapp },
  { path: '/messagingcenter/configuracionEstados', component: ConfiguracionMCEstado },
  { path: '/messagingcenter/configuracionEmail', component: ConfiguracionMCEmail },
  { path: '/messagingcenter/altaWhatsapp', component: AltaWhatsapp },
  // whatsapp
  { path: '/whatsapp/automatizacion', component: Automatizacion },
  { path: '/whatsapp/plantillas', component: Plantillas },
  { path: '/whatsapp/logEnvios/', component: RegistroEnvios },
  { path: '/whatsapp/costes/', component: Costes },
  { path: '/whatsapp/plantillas/nuevaPlantilla/', component: NuevaPlantilla },
  { path: '/whatsapp/plantillas/editarPlantilla', component: EditaPlantilla },
  { path: '/whatsapp/alta', component: Alta },
  // administracion
  { path: '/administracion/miembros', component: Miembros },
  { path: '/administracion/miembros2', component: Miembros2 },
  { path: '/administracion/logacceso', component: LogAcceso },
  { path: '/administracion/miembros/:idMiembro', component: Miembro },
  { path: '/administracion/duplicados', component: ListadoDuplicadosActivos },
  // locales
  { path: '/listado/clientes', component: ListadoClientes },
  { path: '/locales/duplicados', component: ListadoDuplicados },
  { path: '/locales/reservas', component: ListadoReservas },
  { path: '/listado/checkin', component: ListadoCheckin },
  // { path: '/listado/checkin', component: ListadoCheckin },
  { path: '/locales/reservas/detallesCheckin/:idReserva', component: DetalleCheckin },
  // estadisticas
  { path: '/home', component: Home },
  { path: '/estadisticas', component: EstadisticasGeneral },
  { path: '/panelVisitantes', component: PanelVisitantes },
  { path: '/messagingcenter/chatbotDiagrama/webchat/:idDiagrama', component: DiagramaChatbot },
  { path: '/messagingcenter/chatbotDiagrama/whatsapp/:idDiagrama', component: DiagramaChatbotWhatsapp },
  { path: '/messagingcenter/chatbots', component: ListadoDiagramasChatbot },

  // ficha de cliente
  { path: '/fichaCliente/:idCliente', component: FichaCliente },
  // segmentos
  { path: '/segmentos', component: Segmentos },
  // guest journey
  { path: '/guestjourney/automatismos', component: Automatismos },
  { path: '/guestjourney/logenvios', component: RegistroEnviosAutomatismos },
  { path: '/guestjourney/automatismos/:idCamp', component: NuevoAutomatismoMail },
  { path: '/guestjourney/informe', component: InformeAutomatismo },
  // campañas
  { path: '/campanias/mail', component: CampanasCorreo },
  { path: '/campanias/informe', component: Informe },
  { path: '/campanias/mail/:idCamp', component: NuevaCampanaMail },
  { path: '/campanias/whatsapp', component: CampanasWhatsapp },
  { path: '/campanias/whatsapp/:idCamp', component: NuevaCampanaWhastapp },
  // contenido
  { path: '/contenido/mail', component: PlantillasCorreo },
  { path: '/contenido/mail/:idPlantilla', component: NuevaPlantillaCorreo },
  // pagos
  { path: '/pagos/clientes', component: PagosClientes },
  // configuracion
  { path: '/local/configuracion/general', component: General },
  { path: '/local/configuracion/social', component: Social },
  { path: '/local/configuracion/idiomas', component: Idiomas },
  { path: '/local/configuracion/hotspot', component: Hotspot },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/perfil-usuario" /> }
];

const authRoutes = [
  // Login
  { path: '/login', component: Login },
  // Recuperación contraseña
  { path: '/password-recovery', component: RecuperarPassword },
  // Pagina de comprueba email
  { path: '/email-check', component: CompruebaEmail },
  // Pagina de nueva contraseña
  { path: '/password-reset/:reset', component: CambiarPassword },
  { path: '/password-change', component: CambiarPasswordNueva },
  { path: '/preview', component: VerPlantillaCorreoExterno }
];

export { userRoutes, authRoutes };
