import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';
import SidebarContent from './SidebarContent';

import logoLightPng from '../../assets/images/logo_journeytok_verde.png';
import logoLightSvg from '../../assets/images/logotipo_journeytok.png';

const Sidebar = props => {
  const { type } = props;
  return (
    <>
      <div className="vertical-menu">
        <div className="navbar-brand-box">

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="30" style={{ marginTop: '2vh' }} />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background" />
      </div>
    </>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string
};

export default (withTranslation()(Sidebar));
