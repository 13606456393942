import axios from 'axios';
// i18n
import i18n from '../i18n';

const store = require('store');

const API_URL = '';

const axiosApi = axios.create({
  baseURL: API_URL
});

axiosApi.interceptors.response.use(
  response => {
    const { usuario } = response.data;
    if (usuario) {
      const username = {
        name: usuario.name,
        lastname: usuario.lastname,
        email: usuario.email,
        user_hash: usuario.user_hash,
        origen: usuario.origen,
        language: usuario.language,
        plataforma: usuario.plataforma,
        company: {
          id: usuario.company.id,
          name: usuario.company.name,
          type: usuario.company.type,
          tipoLocal: usuario.company.tipoLocal
        }
      };
      store.set('userName', username);
      if (username.language) {
        // set language as i18n
        i18n.changeLanguage(username.language);
        localStorage.setItem('I18N_LANGUAGE', username.language);
      }
    }
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      window.alert(i18n.t('Session lost. Reloggin'));
      window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
      throw Error('Unauthorized');
    }
    return error;
  }
);

export default axiosApi;
