// import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Dropdown } from 'reactstrap';
import { useLocation/* , Link  */ } from 'react-router-dom';

// i18n
import { withTranslation } from 'react-i18next';
import axiosApi from '../../helpers/apiResquests';

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import LocalesSeleccion from '../CommonForBoth/TopbarDropdown/LocalesSeleccion';
// import megamenuImg from '../../assets/images/megamenu-img.png';

const store = require('store');

const Header = React.memo(props => {
  const { t } = props;
  // const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  // const [socialDrp, setsocialDrp] = useState(false);
  const [locales, setLocales] = useState();
  const [divisiones, setDivisiones] = useState();
  const [local, setLocal] = useState();
  const [division, setDivision] = useState();
  const [menuColapsado, setMenuColapsado] = useState(store.get('menu') ? store.get('menu') : false);
  // eliminar cuando se elimine la barra de aviso del nuevo front

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // const { location } = props;
  const location = useLocation();

  /* function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */
  /* !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  } */
  const cargaDatos = async () => {
    try {
      const vistaSuper = localStorage.getItem('vistaSuper')?.toString() === '1' || false;
      const response = await axiosApi.post('/api/localesDivisiones', { vistaSuper });
      const result = response.data;
      setLocales(result.locales);
      setDivisiones(result.divisiones);
      setLocal(result.localSelected);
      setDivision(result.divisionSelected);
      if (result.divisionSelected.idDivision) {
        localStorage.setItem('div', 'true');
        localStorage.setItem('idLocal', `div-${result.divisionSelected.idDivision}`);
      } else {
        localStorage.setItem('div', 'false');
        localStorage.setItem('idLocal', result.localSelected.idLocal);
      }
      store.set('funcionalidades', result.funcionalidades);
      store.set('adm', result.adm);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    cargaDatos();
    const { body } = document;
    if (menuColapsado) {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }, [location.pathname]);

  function tToggle() {
    const { body } = document;
    store.set('menu', !menuColapsado);
    body.classList.toggle('vertical-collpsed');
    body.classList.toggle('sidebar-enable');
    setMenuColapsado(!menuColapsado);
  }

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <Dropdown
              className="dropdown-mega d-lg-block ms-2"
              isOpen={megaMenu}
              toggle={() => {
                setmegaMenu(!megaMenu);
              }}
            >
              <LocalesSeleccion
                local={local}
                division={division}
                locales={locales}
                divisiones={divisiones}
              />
            </Dropdown>
          </div>
          <div className="d-flex">
            <Button
              type="button"
              color="link"
              style={{ color: '#555b6d' }}
              onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm(t('Do you want to open the help center?')) === true) {
                  window.open('https://helpcenter.journeytok.com/portal/es/home', '_blank', 'noreferrer');
                }
              }}
            >
              <i className="bx bx-help-circle font-size-22" />
            </Button>

            {false && <LanguageDropdown />}
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </>
  );
});

Header.propTypes = {
  // changeSidebarType: PropTypes.func,
  // leftMenu: PropTypes.any,
  // leftSideBarType: PropTypes.any,
  // showRightSidebar: PropTypes.any,
  // showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  // toggleLeftmenu: PropTypes.func
  // location: PropTypes.object,
};

export default (withTranslation()(Header));
