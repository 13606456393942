import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { map } from 'lodash';
import moment from 'moment';

// i18n
import { withTranslation } from 'react-i18next';
import axiosApi from '../../../helpers/apiResquests';

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false);
  const [notificaciones, setNotificaciones] = useState([]);

  const { t } = props;

  const cargaDatos = async () => {
    try {
      const response = await axiosApi.get('/api/notificaciones');
      const { data } = response;
      setNotificaciones(data);
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
      console.log(error);
    }
  };

  const marcarLeidas = async () => {
    try {
      await axiosApi.post('/api/notificacionesLeidas', {
        id_notificacion: notificaciones[0].id_notificaciones
      });
      cargaDatos();
    } catch (error) {
      // window.location.href = `${process.env.REACT_APP_HOST_PANEL}/auth/login`;
      console.log(error);
    }
  };

  useEffect(() => {
    cargaDatos();
  }, []);

  useEffect(() => {
    if (notificaciones.length === 0) {
      setMenu(false);
    }
  }, [notificaciones]);

  const coloresNotificaciones = ['bg-primary', 'bg-warning', 'bg-success'];

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {notificaciones.length !== 0 && (<span className="badge bg-danger rounded-pill">{notificaciones.length}</span>)}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{t('Notifications')}</h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: '230px' }}>
            {map(notificaciones, (notificacion, index) => (
              <Link to="" className="text-reset notification-item" key={index}>
                <div className="media">
                  <div className="avatar-xs me-3">
                    <span className={`avatar-title ${coloresNotificaciones[index % 3]} rounded-circle font-size-16`}>
                      {notificacion.tipo === 'NUEVA_TAREA' && (
                      <i className="bx bx-task" />)}
                      {notificacion.tipo === 'NUEVO_LEAD' && (
                      <i className="bx bx-user-circle" />)}
                      {notificacion.tipo === 'NUEVO_ARCHIVO' && (
                      <i className="bx bx-file-blank" />)}
                    </span>
                  </div>
                  <div className="media-body">
                    {notificacion.tipo === 'NUEVA_TAREA' && (
                      <h6 className="mt-0 mb-1">{t('New task assigned')}</h6>)}
                    {notificacion.tipo === 'NUEVO_LEAD' && (
                      <h6 className="mt-0 mb-1">{t('A new lead has been assigned')}</h6>)}
                    {notificacion.tipo === 'NUEVO_ARCHIVO' && (
                      <h6 className="mt-0 mb-1">{t('The file is now available for download')}</h6>)}
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{notificacion.contenido}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        {' '}
                        {moment(notificacion.fecha_hora).fromNow(true)}
                        {' '}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          {notificaciones?.length !== 0 && (
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                to="#"
                onClick={() => { marcarLeidas(); }}
              >
                <i className="mdi mdi-arrow-right-circle me-1" />
                {t('Mark as read')}
                {' '}
              </Link>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
