import React, { useState } from 'react';

function WebchatWidget() {
  const [isVisible, setIsVisible] = useState(false);
  const [burbujaOculta, setBurbujaOculta] = useState(false);

  const toggleChat = () => {
    setIsVisible(!isVisible);
  };

  const checkMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // eslint-disable-next-line no-useless-escape
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/.test(userAgent.substr(0, 4));
  };

  const adjustStyle = () => {
    const botonAbrir = document.getElementById('journeytokBurbujaChatAbrir');
    const elementoOculto = document.getElementById('journeytokBurbujaChat');
    const chat = document.getElementById('frameChatJourney');
    if (!botonAbrir || !elementoOculto || !chat) {
      return;
    }
    if (checkMobile()) {
      botonAbrir.style.right = '15px';
      elementoOculto.style.top = '0px';
      elementoOculto.style.left = '0px';
      elementoOculto.style.right = '0px';
      elementoOculto.style.bottom = '0px';
      elementoOculto.style.margin = '0px';
      chat.style.width = '100vw';
      chat.style.height = '100vh';
    } else {
      botonAbrir.style.right = '50px';
      elementoOculto.style.right = '20px';
      chat.style.width = '30vw';
      chat.style.height = '90vh';
      elementoOculto.style.margin = 'initial';
    }
  };

  window.addEventListener('load', adjustStyle);
  window.addEventListener('resize', adjustStyle);

  return (
    <div>
      {!burbujaOculta && (
        <>
          <div
            id="ocultarBurbujaChat"
            style={{ position: 'fixed', bottom: '65px', right: '45px', zIndex: '1000000', fontSize: '16px' }}
          >
            <i className="far fa-caret-square-down" onClick={() => setBurbujaOculta(true)} style={{ cursor: 'pointer' }} />
          </div>
          <div
            id="journeytokBurbujaChatAbrir"
            style={{ cursor: 'pointer', position: 'fixed', bottom: '15px', right: '50px', zIndex: '1000000' }}
            onClick={toggleChat}
          >
            <img
              id="svgBurbujaChat"
              alt="Chat"
              src="https://imageswfz.s3.eu-west-1.amazonaws.com/chatbot/Icono-chat.png"
              style={{ width: '65px', height: '65px' }}
            />
          </div>
          <div
            id="journeytokBurbujaChat"
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: '1000000',
              boxShadow: '0px 0px 3px 3px lightgray',
              borderRadius: '20px',
              display: isVisible ? 'block' : 'none',
            }}
          >
            <button
              id="journeytokBurbujaChatCerrar"
              style={{
                background: 'none',
                border: '0',
                fontSize: '25px',
                position: 'absolute',
                top: '9px',
                right: '3px',
                width: '1em',
                height: '1em',
                zIndex: '2',
                cursor: 'pointer',
                padding: '0px !important',
                color: 'initial',
              }}
              type="button"
              onClick={toggleChat}
            >
              &#8211;
            </button>
            <iframe
              id="frameChatJourney"
              src="https://chat.theguestway.com/nuevo/chat?id=9731364&origen=europe"
              title="chat"
              scrolling="no"
              style={{
                overflow: 'hidden',
                borderRadius: '20px',
                width: '30vw',
                height: '90vh',
              }}
            />
          </div>
        </>
      )}
      {burbujaOculta && (
        <div
          id="mostrarBurbujaChat"
          style={{ position: 'fixed', bottom: '-7px', right: '45px', zIndex: '1000000', fontSize: '16px' }}
        >
          <i className="far fa-caret-square-up" onClick={() => setBurbujaOculta(false)} style={{ cursor: 'pointer' }} />
        </div>
      )}
    </div>
  );
}

export default WebchatWidget;
